import React from "react";
import { NavLink} from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  let companyId = companyInfo.id
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <div className="mowaamah-sidebar">
      <NavLink to={`/${companyId}/`} className="nav-link" role="button">
        {({ isActive }) => (
          <React.Fragment>
            <i
              className={`bi ${
                isActive ? "bi-house-door-fill" : "bi-house-door"
              }`}
            ></i>{" "}
            {local.home}
          </React.Fragment>
        )}
      </NavLink>
      <NavLink to={`/${companyId}/entity`} className="nav-link" role="button">
        {({ isActive }) => (
          <React.Fragment>
            <i
              className={`bi ${
                isActive ? "bi-file-text-fill" : "bi-file-text"
              }`}
            ></i>
            {local.entityDetails}
          </React.Fragment>
        )}
      </NavLink>
      <NavLink
        to={`/${companyId}/certificate`}
        className="nav-link"
        role="button"
      >
        {({ isActive }) => (
          <React.Fragment>
            <i
              className={`bi ${
                isActive
                  ? "bi-file-earmark-medical-fill"
                  : "bi-file-earmark-medical"
              }`}
            ></i>
            {local.mowaamahCertificate}
          </React.Fragment>
        )}
      </NavLink>
      <NavLink to={`/${companyId}/training`} className="nav-link" role="button">
        {({ isActive }) => (
          <React.Fragment>
            <i
              className={`bi ${
                isActive ? "bi-person-vcard-fill" : "bi-person-vcard"
              }`}
            ></i>
            {local.training}
          </React.Fragment>
        )}
      </NavLink>
      <NavLink to={`/${companyId}/support`} className="nav-link" role="button">
        {({ isActive }) => (
          <React.Fragment>
            <i
              className={`bi ${
                isActive ? "bi-chat-left-text-fill" : "bi-chat-left-text"
              }`}
            ></i>
            {local.contactUs}
          </React.Fragment>
        )}
      </NavLink>
    </div>
  );
};

export default Sidebar;
