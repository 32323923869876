import { Route, Routes } from "react-router-dom";
import NoMatch from "../../components/Common/NoMatch";
import Home from "../../components/Home";
import Entity from "../Entity";
import Training from "../Training";
import Certificate from "../Certificate";
import Payment from "../Payment";
import ContactPage from "../../components/Landing/contact";

const Company = () => {
  return (
    <Routes>
      <Route path="payment/*" element={<Payment />} />
      <Route path="support/*" element={<ContactPage />} />
      <Route path="certificate/*" element={<Certificate />} />
      <Route path="entity/*" element={<Entity />} />
      <Route path="training/*" element={<Training />} />
      <Route path="" element={<Home />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default Company;
