import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "./Error/reducers";
import commonReducer from "./Common/reducers";
import certificateReducer from "./Certificate/reducers";
import authReducer from "./Auth/reducers";
import paymentReducer from "./Payment/reducers";
import homeReducer from "./Home/reducers";
import { checkForExpiredToken } from "./Auth/actions";
import {
  setupAuthInterceptor,
  setupPublicInterceptor,
} from "../utils/axiosConfig";
import trainingReducer from "./Training/reducers";
import supportReducer from "./Support/reducers";

const store = configureStore({
  reducer: {
    commonReducer: commonReducer,
    errorReducer: errorReducer,
    authReducer: authReducer,
    certificateReducer: certificateReducer,
    paymentReducer: paymentReducer,
    homeReducer: homeReducer,
    trainingReducer: trainingReducer,
    supportReducer: supportReducer,
  },
});

setupAuthInterceptor(store);
setupPublicInterceptor();

store.dispatch(checkForExpiredToken());

export default store;
