import { AuthInstance } from "../../utils/axiosConfig";
import { setErrors } from "../Error/actions";
import { resetErrors, resetError } from "../Error/epics";
import { useSelector } from "react-redux";
import { setLoading } from "../Common/actions";
import * as epics from "./epics";
import { toast } from "react-toastify";

export const fetchPrivateCompaniesList = (param) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      // let response = await AuthInstance.get(`companies/private/`);
      let response = await AuthInstance.get(
        `companies/private_qiwa/?is_login=${param.is_login}`
      );
      let data = await response.data;

      dispatch(epics.setMyCompaniesList(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchGovCompaniesList = (navigate, local) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(`companies/government/`);
      let data = await response.data;

      dispatch(epics.setMyCompaniesList(data.payload.data));
      if (data?.payload?.data?.length < 1) {
        toast.error(local.noCompaniesFound);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchCompanyInfo = (companyId, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(`companies/${companyId}/`);
      let data = await response.data;

      dispatch(epics.setCompanyInfo(data.payload.data));
      if (data?.payload?.data?.is_registration_completed) {
        navigate && navigate(`/${companyId}`);
      } else {
        navigate && navigate(`/${companyId}/entity/update`);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchRegionsList = () => {
  return async (dispatch) => {
    try {
      dispatch(resetError("region"));

      let response = await AuthInstance.get(`regions/`);
      let data = await response.data;
      dispatch(epics.setRegionsList(data?.payload?.data));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchCitiesList = (regionId) => {
  return async (dispatch) => {
    try {
      dispatch(resetError("city"));

      let response = await AuthInstance.get(`cities/?region_id=${regionId}`);
      let data = await response.data;

      dispatch(epics.setCitiesList(data?.payload?.data));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
export const fetchFaqsList = () => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      let response = await AuthInstance.get(`content/faq/`);
      let data = await response.data;
      dispatch(epics.setFaqsList(data?.payload?.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
