import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate } from "react-router-dom";
import DropDownInput from "../../../shared/FormElements/DropDownInput";
import { setCompanyInfo } from "../../../store/Home/epics";
import {
  fetchPrivateCompaniesList,
  fetchGovCompaniesList,
  fetchCompanyInfo,
} from "../../../store/Home/actions";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { Button, Row, Col, Card, Container } from "react-bootstrap";
import isEmpty from "../../../utils/isEmpty";
import { fetchUserInfo } from "../../../store/Auth/actions";
import { toast } from "react-toastify";

const SelectCompany = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();
  const myCompaniesList = useSelector(
    (state) => state.homeReducer.myCompaniesList
  );
  const loading = useSelector((state) => state.commonReducer.loading);
  const errors = useSelector((state) => state.errorReducer.errors);
  const user = useSelector((state) => state.authReducer.user);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  useLegacyEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  useLegacyEffect(() => {
    if (
      !isEmpty(myCompaniesList) &&
      user.user_type === "PRIVATE" &&
      !user.user_type
    ) {
      dispatch(fetchUserInfo());
    }
    if (!isEmpty(myCompaniesList) && user.user_type === "GOVERNMENT") {
      setSelectedCompanyId({
        value: myCompaniesList[0].id,
        label: `${myCompaniesList[0].commercial_record_number || ""} - ${
          local.language === "ar"
            ? myCompaniesList[0].name
            : myCompaniesList[0].name_en
        }`,
      });
    }
  }, [myCompaniesList, user]);
  useLegacyEffect(() => {
    if (isEmpty(myCompaniesList) && user.user_type === "GOVERNMENT") {
      dispatch(fetchGovCompaniesList(navigate, local));
    } else if (isEmpty(myCompaniesList) && user.user_type === "PRIVATE") {
      dispatch(fetchPrivateCompaniesList({ is_login: true }));
    }
  }, [user]);

  const myCompaniesListOptions =
    (!isEmpty(myCompaniesList) &&
      myCompaniesList.map((company) => ({
        value: company.id,
        label: `${company.commercial_record_number || ""} - ${
          local.language === "ar" ? company.name : company.name_en
        }`,
      }))) ||
    [];

  const dispatch = useDispatch();

  const handleOnChange = (data) => {
    setSelectedCompanyId(data);
  };

  const handleSelectCompany = () => {
    if (selectedCompanyId !== "") {
      const company = myCompaniesList.find(
        (company) => company.id === selectedCompanyId.value
      );
      localStorage.setItem("companyName", company.name);

      dispatch(fetchCompanyInfo(company.id, navigate));
    } else {
      toast.error(local.needToChooseCompany);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs={12} lg={6}>
          <Card className="login-card shadow px-3 py-5 px-lg-5 mb-5 w-100">
            <DropDownInput
              showDefaultOption={user.user_type !== "GOVERNMENT"}
              inputId="select_company"
              options={myCompaniesListOptions}
              defaultOption={local.selectCompany}
              label={local.selectCompanyToLogin}
              inputName={"selectedCompanyId"}
              inputValue={selectedCompanyId}
              isInvalid={errors.selectCompany}
              error={errors.selectCompany}
              onChange={handleOnChange}
            />
          </Card>
        </Col>
      </Row>

      <Row className="row justify-content-center mb-5">
        <Col className="col-lg-6 text-center">
          <Button
            disabled={isEmpty(myCompaniesList)}
            variant="secondary"
            className="text-white w-75"
            onClick={handleSelectCompany}
            type="button"
          >
            {local.loginToCompanyPage}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SelectCompany;
