import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import Loader from "../../Common/Loader";
import { setPaymentParam } from "../../../utils/localStorage";
import successIcon from "../../../assets/images/Payment/successIcon.svg";
import ChevronBar from "../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { fetchAssessmentOrder } from "../../../store/Certificate/actions";
import { Button, Container } from "react-bootstrap";
import { fetchTrainingOrder } from "../../../store/Training/actions";
import { orderType } from "../../../lookups/status";
import SurveyModal from "../Survey";
const PaymentSuccessful = () => {
  const local = useSelector((state) => state.homeReducer.local);

  const dispatch = useDispatch();
  const query = useQuery();
  let { uuid, companyId } = useParams();
  const navigate = useNavigate();
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const loading = useSelector((state) => state.commonReducer.loading);
  setPaymentParam({ isSet: false });
  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_EVIDENCE")
      );
      dispatch(setCurrentCertificateFlowStep(1.5));
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(
          navigate,
          uuid,
          companyId,
          "PENDING_TRAINING_SCHEDULE"
        )
      );
    } else {
      navigate(`/${companyId}`);
    }
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row justify-content-center mb-5">
            <div className="col-12 text-center">
              {query.get("type") === orderType.certificate && (
                <ChevronBar
                  type={"shape"}
                  stepsClass={"steps-flow-container"}
                  stepClass={"step-flow"}
                  stepText={"step-flow-text"}
                  stepTextCurrent={"step-flow-text--current"}
                  stepTextUncompleted={"step-flow-text--uncompleted"}
                  stepTextCompleted={"step-flow-text--completed"}
                  stepCompletedClass={"step-home--completed"}
                  steps={flowSteps}
                  currentStep={currentCertificateFlowStep}
                />
              )}

              <img
                className="payment-successful-icon mt-5"
                src={successIcon}
                alt="Success Icon"
                aria-hidden="true"
              />

              <h1 className="text-success mt-3 mb-5">
                {local.paymentSuccessful}
              </h1>

              <p className="fs-2 text-gray">
                {query.get("type") === orderType.certificate &&
                  local.certificatePaymentSuccessfulNote}
                {query.get("type") === orderType.training &&
                  local.trainingPaymentSuccessfulNote}
              </p>
            </div>
          </div>

          {query.get("type") === orderType.certificate && (
            <>
              <div className="row justify-content-center">
                <div className="col-12 col-md-4 text-center">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      navigate(`/${companyId}/certificate/${uuid}/evidence`)
                    }
                    className="text-white w-100"
                    type="submit"
                  >
                    {local.addEvidence}
                  </Button>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-4 text-center">
                  <button
                    onClick={() => navigate(`/${companyId}`)}
                    className="btn text-secondary mt-2 w-100"
                    type="submit"
                  >
                    {local.backToHomePage}
                  </button>
                </div>
              </div>
            </>
          )}
          {query.get("type") === orderType.training && (
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 text-center">
                <Button
                  variant="secondary"
                  className="text-white w-100"
                  type="button"
                  onClick={() => navigate(`/${companyId}`)}
                >
                  {local.backToHomePage}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {/* <SurveyModal uuid={uuid} type={"payment"} /> */}
    </Container>
  );
};

export default PaymentSuccessful;
